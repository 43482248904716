import {
    BaseQueryFn,
    createApi,
    fetchBaseQuery,
    FetchBaseQueryError,
    FetchBaseQueryMeta,
    retry
} from '@reduxjs/toolkit/query/react'
import {setGlobalError} from "./globalSlice/globalSlice";
import {QueryReturnValue} from "@reduxjs/toolkit/dist/query/baseQueryTypes";
import {skipToken} from "@reduxjs/toolkit/query";

let errorHandlingOptions: ExtraOptions | null = null;

// Define the extra options interface
export interface ExtraOptions {
    redirectToErrorPage?: boolean;
    skip?: boolean;
}

export const fetchWithExtraOptions = <T>(
    endpoint: keyof typeof strApi.endpoints,
    args: T,
    extraOptions: ExtraOptions = {}
) => {
    const skip = errorHandlingOptions?.skip || false;

    if (skip) {
        return skipToken;
    }

    errorHandlingOptions = extraOptions;

    // @ts-ignore
    return endpoint.initiate();
};


const customBaseQuery = fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
});

// Define the custom base query with error handling
const baseQueryWithErrorHandling: BaseQueryFn<
    string | { url: string; method?: string; headers?: Record<string, string> },
    unknown,
    FetchBaseQueryError,
    ExtraOptions,
    FetchBaseQueryMeta
> = async (args, api, extraOptions = {}) => {

    // Destructure the options and set default values
    const redirectToErrorPage = errorHandlingOptions?.redirectToErrorPage || false;

    // Make the request using the custom base query
    const result: QueryReturnValue<unknown, FetchBaseQueryError, FetchBaseQueryMeta> = await customBaseQuery(args, api, extraOptions);

    // Check for errors and handle redirection if needed
    if (result.error && redirectToErrorPage) {
        // Dispatch the global error with the error message and status
        api.dispatch(
            setGlobalError({
                statusCode: result.error.status || 500,
                message: (result.error.data as { message?: string })?.message || "An unexpected error occurred",
            })
        );
    }

    // Reset error handling options after processing the request
    errorHandlingOptions = { redirectToErrorPage: false };

    return result;
};


// Wrap with retry logic and set maxRetries to 1
export const strApi = createApi({
    reducerPath: "strApi",
    baseQuery: retry(baseQueryWithErrorHandling, {
        maxRetries: 0,
    }),
    refetchOnMountOrArgChange: 15,

    tagTypes: [
        'userSettings',
        'userProfileInfo',
        'orderNoteList',
        'productDocumentList',
        'productById',
        'requirementsForInstrument',
        'recipientInstruments',
        'systemPropertyById',
        'users',
        'userById',
        'systemProperties',
        'recordingPackageByProductId',
        'cannedNotes',
        'customers',
        'customerById',
        'branches',
        'regions',
        'regionById',
        'branchContacts',
        'pdfSettingsByCustomerId',
        'fetchSimplifileTokenStatus',
        'followUpTypeById',
        'followUpTypes',
        'products',
        'findAllRoles',
        'roleById',
        'states',
        'stateById',
        'cannedNoteById',
        'counties',
        'countyById',
        'teams',
        'teamById',
        'teamLeaders',
        'teamMembers',
        'teamRules',
        'teamRuleById',
        'metroAreas',
        'cannedFileNames',
        'cannedFileNameById',
        'metroAreaById',
        'platformPricing',
        'revenueEvents',
        'platformPricingById',
        'tenantPricing',
        'tenantPricingById',
        'partnerPricing',
        'partnerPricingById',
        'vendorPricing',
        'vendorPricingById',
        'assignmentLogs',
        'invoiceLines',
        'invoiceLineById',
        'productPricing',
        'simplifileConfigById',
        'simplifileConfigs',
        'branchById',
        'invoiceLines',
        'invoiceNotes',
        'deliveryModalDocuments',
        'orderLogs',
        'userAuditableInfo',
        'roles',
        'defaultInvoiceLines',
        'teamAndUserAssignments'
    ],
    endpoints: () => ({}),
})